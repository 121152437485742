<script>
  import Autopause from 'app/mixins/Autopause'

  export default {
    mixins: [Autopause],
    data () {
      return {
        active: 0
      }
    },
    methods: {
      change_slide (index) {
        this.active = index
      },
      slide (shift) {
        let new_active = this.active + shift

        if (this.slideable(shift)) {
          this.active = this.active + shift

          let video = this.$refs[`video-${this.active}`]

          try {
            video.pause()
            video.currentTime = 0
            video.play()
          } catch (e) {
          }
        }
      },
      slideable (shift) {
        let new_active = this.active + shift

        if (new_active >= 0 && new_active <= 5) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
