<template>
  <a class="cookie-privacy-settings" @click.prevent="showModal">{{linkText()}}</a>
</template>

<script>
  export default {
    props: ["show_cookies_settings_modal"],
    methods: {
      linkText () {
        return window.cookiePrivacy?.locale?.isCCPA ? "Do Not Sell My Personal Information" : "Cookie Settings";
      },
      showModal () {
        this.$emit("update:show_cookies_settings_modal", true)
      }
    }
  }
</script>
