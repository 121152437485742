<script>
  import SpinnerButton from 'app/components/shared/SpinnerButton'
  import { required, email, maxLength } from 'vuelidate/lib/validators'

  export default {
    data () {
      return {
        submitting: false,
        name: '',
        email: '',
        state: '',
        country: '',
        message: ''
      }
    },
    validations: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      email: {
        required,
        email
      },
      state: {
        required
      },
      country: {
        required
      },
      message: {
        required
      }
    },
    methods: {
      contact () {
        this.submitting = true
        this.$v.$touch()

        let body = {
          name: this.name,
          email: this.email,
          message: `${this.message}\n\nState: ${this.state}\nCountry: ${this.country}`,
          intercom_visitor_id: Intercom('getVisitorId')
        }

        if (this.$v.$error) {
          let message = 'Some fields have errors. Please correct them and try again.'
          this.$toast(message, { horizontalPosition: 'center', verticalPosition: 'top' })
          this.submitting = false
        } else {
          this.$http.post('/contacts', body).then(response => {
            this.submitting = false
            this.$v.$reset()
            this.name = ''
            this.email = ''
            this.state = ''
            this.country = ''
            this.message = ''
            let message = "Your message has been sent. We'll get back to you soon!"
            this.$toast(message, { horizontalPosition: 'center', verticalPosition: 'top' })
          }, response => {
            this.submitting = false
            let error = response.body.error || 'Something went wrong. Please try again or contact support.'
            this.$toast(error, { horizontalPosition: 'center', verticalPosition: 'top' })
          })
        }
      }
    },
    components: { SpinnerButton }
  }
</script>
