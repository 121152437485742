<script>
  export default {
    data () {
      return {
        active: 0,
        beforeWidth: 50.0,
        hideLabels: false,
        labelWidth: 207,
      }
    },
    created () {
      this.active = 0;
    },
    mounted () {
      this.labelWidth = Math.min(this.$refs['container_' + this.active].offsetWidth * 0.4, 207)
    },
    methods: {
      change_slide (index) {
        this.active = index
        this.hideLabels = false
      },
      mouse_down (event) {
        document.addEventListener('mousemove', this.move, { passive: false })
        document.addEventListener('mouseup', this.mouse_up, { passive: false })
        document.addEventListener('touchmove', this.move, { passive: false })
        document.addEventListener('touchend', this.mouse_up, { passive: false })
        this.move(event)
        this.hideLabels = true
      },
      move (event) {
        event.preventDefault()

        let x = event.touches ? event.touches[0].clientX : event.clientX
        let offsetLeft = this.$refs['container_' + this.active].offsetLeft
        let fullWidth = this.$refs['container_' + this.active].offsetWidth

        let newBeforeWidth = (x - offsetLeft) / fullWidth
        let newBeforeWidthBounded = (Math.max(Math.min(newBeforeWidth, 1), 0)) * 100

        // console.log(newBeforeWidthBounded)

        this.beforeWidth = newBeforeWidthBounded
      },
      mouse_up () {
        document.removeEventListener('mousemove', this.move)
        document.removeEventListener('mouseup', this.move)
        document.removeEventListener('touchmove', this.move)
        document.removeEventListener('touchend', this.move)
        this.hideLabels = false
      },
      registerContainer (el) {
        console.log("registering...", el)
      }
    }
  }
</script>
