<template>
  <button :disabled='spin' :class='{ spinning: spin }'>
    <slot></slot>
    <template v-if='spin'>
      <div class="la-ball-pulse">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>
  </button>
</template>

<script>
  export default {
    props: ['spin']
  }
</script>
