export default {
  methods: {
    autopause (is_visible, observer) {
      try {
        if (observer && observer.target) {
          const { currentTime, paused, ended, readyState } = observer.target
          const puasable = !!(currentTime > 0 && !paused && !ended && readyState > 2)

          if (is_visible && (readyState > 2)) {
            observer.target.play()
          } else {
            if (puasable) {
              observer.target.pause()
            }
          }
        }
      } catch (e) {
      }
    }
  }
}
