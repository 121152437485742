<template>
  <transition name="modal">
    <div v-if="show" class="modal-wrapper a2-comparison-modal-wrapper">
      <div class="modal a2-comparison-modal">
        <div class="modal-body">
          <div class="comparison-chart">
            <table>
              <thead>
                <tr>
                  <th>Compare</th>
                  <th>Arsenal 1</th>
                  <th>
                    <div class="comp-hl" style="border-radius: 8px 0 0 0">
                      <span>
                        Arsenal 2<br />
                        <span>Standard</span>
                      </span>
                    </div>
                  </th>
                  <th>
                    <div class="comp-hl" style="border-radius: 0 8px 0 0">
                      <span>
                        Arsenal 2<br />
                        <span>Pro</span>
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="comp-section">
                    <a @click.prevent="show_hardware">Hardware</a>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr v-show="comp_hw">
                  <td>Wi-Fi</td>
                  <td>802.11n</td>
                  <td>802.11ac</td>
                  <td>802.11ac</td>
                </tr>

                <tr v-show="comp_hw">
                  <td>Micro SD Slot</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_hw">
                  <td>USB</td>
                  <td>USB Micro</td>
                  <td>USB Micro</td>
                  <td>USB-C</td>
                </tr>

                <tr v-show="comp_hw">
                  <td>Weather Resistant</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_hw">
                  <td>Performance</td>
                  <td>–</td>
                  <td>
                    300% Faster <span class="comp-a1">than Arsenal 1</span>
                  </td>
                  <td>
                    500% Faster <span class="comp-a1">than Arsenal 1</span>
                  </td>
                </tr>

                <tr>
                  <td class="comp-section">
                    <a @click.prevent="show_features">Features</a>
                  </td>
                  <td></td>
                  <td class="comp-banner" colspan="2">
                    <div v-show="comp_features">Second Generation AI</div>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Smart Mode</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Manual Control</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Focus Stacking</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Exposure Bracketing</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Long Exposure Stacking</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Timelapse / Holy Grail</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr v-show="comp_features">
                  <td>Save to Phone</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr class="new-feature" v-show="comp_features">
                  <td>Save to Micro SD</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr class="new-feature" v-show="comp_features">
                  <td>Deep Color</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr class="new-feature" v-show="comp_features">
                  <td>Panorama</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr class="new-feature" v-show="comp_features">
                  <td>Crowd Control</td>
                  <td>
                    <svg class="icon icon-clear">
                      <use xlink:href="#icon-clear"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>

                <tr class="new-feature" v-show="comp_features">
                  <td>Night Assist</td>
                  <td>Night Exposure Only</td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg class="icon icon-check">
                      <use xlink:href="#icon-check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      comp_features: false,
      comp_hw: true,
    };
  },
  methods: {
    show_features() {
      this.comp_features = true;
      this.comp_hw = false;
    },
    show_hardware() {
      this.comp_features = false;
      this.comp_hw = true;
    },
  },
  props: ["show", "cancel"],
};
</script>
