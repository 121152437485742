<template>
  <iframe id="arsenal-video-inline" :src="videoUrl()" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
  require('app/vendor/youtube_iframe_api')

  export default {
    created () {
      let intercom = document.getElementById('intercom-container')

      if (intercom) {
        intercom.style.display = 'none'
      }

      if (typeof gtag !== 'undefined') {
        gtag('event', 'watch video', { event_category: 'interactions' })
      }
    },
    mounted () {
      const self = this

      window.YT.ready(() => {
        this.player = new YT.Player('arsenal-video-inline', {
          events: {
            onReady (event) {
              event.target.playVideo()
            }
            // onStateChange (event) {
            //   if (event.data === 0) {
            //     self.close_video()
            //   }
            // }
          }
        })
      })
    },
    beforeDestroy () {
      let intercom = document.getElementById('intercom-container')

      if (intercom) {
        intercom.style.display = 'block'
      }
    },
    props: ['muted', 'video'],
    methods: {
      videoUrl () {
        const domain = window.cookiePrivacy.trackingGranted ? "www.youtube.com" : "www.youtube-nocookie.com";
        // const shouldMute = this.muted || ((window.navigator.userAgent.indexOf("Safari") > -1 && window.navigator.userAgent.indexOf("Chrome") == -1) || window.navigator.userAgent.indexOf("Firefox") > -1);
        const shouldMute = this.muted;
        // console.log(`domain: ${domain} muted: ${this.muted} shouldMute: ${shouldMute}`);
        const muteOption = shouldMute ? "&mute=1" : "";

        return `https://${domain}/embed/${this.video}?autoplay=1&enablejsapi=1&rel=0${muteOption}`;
      }
    }
  }
</script>
