<template>
  <div>
    <div id="cookie-privacy-banner" v-if="show_banner && !show_cookies_settings_modal">
      <div class="banner-content">
        <div class="banner-text">
          By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
        </div>

        <div>
          <a @click.prevent="bannerAllowAll" class="btn">Accept All</a>
          <a @click.prevent="hideBanner" class="btn btn-cancel">Close</a>
        </div>
      </div>
    </div>

    <transition name="modal">
      <div v-if="show_cookies_settings_modal" id="cookie-privacy-settings-modal" class="modal-wrapper">
        <div class="modal">
          <i class="icon-close" @click.prevent="modalClose" />

          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h3>Data Preferences</h3>
                <p>
                  We take safeguarding your information seriously. This site uses cookies to work as you expect it to. You can choose which types of cookies to allow below.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>
                  <input id="necessary-cookies" type="checkbox" checked disabled /> <label for="necessary-cookies"><strong>Necessary Cookies</strong></label><br />
                  These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>
                  <input id="performance-cookies" type="checkbox" v-model="performanceGranted" /> <label for="performance-cookies"><strong>Performance Cookies</strong></label><br />
                  These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <p>
                  <input id="advertising-cookies" type="checkbox" v-model="trackingGranted" /> <label for="advertising-cookies"><strong>Advertising Cookies</strong></label><br />
                  These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device.
                </p>
              </div>
            </div>

            <div style="text-align: right">
              <a class="btn" @click.prevent="modalAllowAll" v-if="!trackingGranted || !performanceGranted">Allow All</a>
              <a class="btn btn-cancel" @click.prevent="modalConfirm">Confirm Choices</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show_banner: window.cookiePrivacy && window.cookiePrivacy.shouldShowBanner(),
        trackingGranted: window.cookiePrivacy && window.cookiePrivacy.trackingGranted,
        performanceGranted: window.cookiePrivacy && window.cookiePrivacy.performanceGranted
      };
    },
    props: ["show_cookies_settings_modal"],
    methods: {
      modalAllowAll () {
        this.trackingGranted = true
        this.performanceGranted = true
      },
      modalConfirm () {
        this.performanceGranted ? window.cookiePrivacy.grant("performance") : window.cookiePrivacy.ungrant("performance");
        this.trackingGranted ? window.cookiePrivacy.grant("tracking") : window.cookiePrivacy.ungrant("tracking");
        window.cookiePrivacy.flagPermissionsChosen();

        this.show_banner = window.cookiePrivacy.shouldShowBanner();

        this.modalClose()
      },
      modalClose () {
        this.$emit("update:show_cookies_settings_modal", false)
      },
      hideBanner () {
        window.cookiePrivacy.flagPermissionsChosen();
        this.show_banner = false;
      },
      bannerAllowAll () {
        window.cookiePrivacy.grantAll();
        this.hideBanner();
      }
    }
  }
</script>
