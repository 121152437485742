<script>
  export default {
    created () {
      window.addEventListener('scroll', this.handleScroll, { passive: true })
    },
    mounted () {
      let scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

      if (scrolltop > 10) {
        this.afterHero = true
      }
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    watch: {
      'window.location': function () {
        this.showMenu = false
      }
    },
    props: ['limited'],
    data () {
      return {
        afterHero: false,
        showMenu: false
      }
    },
    methods: {
      transparent () {
        return window.location.pathname === '/' && !this.afterHero
      },
      handleScroll (event) {
        // Hack to get scrolltop from all browsers
        let scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

        if (scrolltop > 10) {
          this.afterHero = true
        } else {
          this.afterHero = false
        }
      },
      toggle () {
        this.showMenu = !this.showMenu
      }
    }
  }
</script>
