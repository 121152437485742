<template>
  <a class="fullscreen" v-if="show_video" @click.prevent="close_video">
    <i class="icon-close" />
    <iframe id="arsenal-video2" width="100%" height="100%" data-src="https://www.youtube.com/embed/PNj8yBI14YQ?autoplay=1&enablejsapi=1&rel=0" class="optanon-category-C0004" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </a>
</template>

<script>
  require("app/vendor/youtube_iframe_api")

  export default {
    created () {
      let intercom = document.getElementById("intercom-container")

      if (intercom) {
        intercom.style.display = "none"
      }

      if (typeof gtag !== 'undefined') {
        gtag('event', 'watch video', { event_cagegory: 'interactions' })
      }
    },
    mounted () {
      const self = this

      const iframe = document.getElementById("arsenal-video2")
      iframe.setAttribute("src", iframe.getAttribute("data-src"))

      this.player = new YT.Player("arsenal-video2", {
        events: {
          onStateChange (event) {
            if (event.data === 0) {
              self.close_video()
            }
          }
        }
      })
    },
    beforeDestroy () {
      let intercom = document.getElementById("intercom-container")

      if (intercom) {
        intercom.style.display = "block"
      }
    },
    props: ["show_video"],
    methods: {
      close_video () {
        this.$emit("update:show_video", false)
      }
    }
  }
</script>
