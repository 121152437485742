<template>
  <a class='fullscreen' v-if='show_video' @click.prevent='close_video'>
    <i class='icon-close' />
    <iframe id='arsenal-video' width='100%' height='100%' :src="videoUrl()" frameborder='0' allowfullscreen></iframe>
  </a>
</template>

<script>
  require('app/vendor/youtube_iframe_api')

  export default {
    created () {
      let intercom = document.getElementById('intercom-container')
      let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher')[0]

      if (intercom) {
        intercom.style.display = 'none'
      }

      if (intercomLauncher) {
        intercomLauncher.style.display = 'none'
      }

      if (typeof gtag !== 'undefined') {
        gtag('event', 'watch video', { event_cagegory: 'interactions' })
      }
    },
    mounted () {
      const self = this

      this.player = new YT.Player('arsenal-video', {
        events: {
          onStateChange (event) {
            if (event.data === 0) {
              self.close_video()
            }
          }
        }
      })
    },
    beforeDestroy () {
      let intercom = document.getElementById('intercom-container')
      let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher')[0]

      if (intercom) {
        intercom.style.display = 'block'
      }

      if (intercomLauncher) {
        intercomLauncher.style.display = 'block'
      }
    },
    props: ['show_video', 'video'],
    methods: {
      videoUrl () {
        if (window.cookiePrivacy.trackingGranted) {
          return `https://www.youtube.com/embed/${this.video}?autoplay=1&enablejsapi=1&rel=0`;
        } else {
          return `https://www.youtube-nocookie.com/embed/${this.video}?autoplay=1&enablejsapi=1&rel=0`;
        }
      },
      close_video () {
        this.$emit('update:show_video', false)
      }
    }
  }
</script>
