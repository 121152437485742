export default function() {
  var videos = document.querySelectorAll('video.lazy-load')
  for (var i = 0; i < videos.length; i++) {
    var video = videos[i];
    var sources = video.querySelectorAll('source')
    // for each video
    for (var j=0;j < sources.length;j++) {
      var source = sources[j];
      source.setAttribute('src', source.getAttribute('data-src'))
    }
    try {
      video.load()
    } catch(e) {
      console.log('unable to load video: ', e);
    }
  }

}
