<script>
import A2ComparisonModal from "app/components/shared/A2ComparisonModal";

export default {
  mounted() {
    const tabsPlaceholder = document.querySelectorAll('.standard-pro-tabs-placeholder')[0]
    const tabs = document.querySelectorAll('.standard-pro-tabs-container')[0]

    window.addEventListener("scroll", (event) => {
      if (tabsPlaceholder) {
        const sticky = tabsPlaceholder.offsetTop

        if (window.pageYOffset > sticky) {
          tabs.classList.add("sticky");
        } else {
          tabs.classList.remove("sticky");
        }
      }
    })
  },
  props: ['initial_tab'],
  data() {
    return {
      tab: this.initial_tab,
      comparison_modal: false,
    };
  },
  methods: {
    change_tab(tab) {
      this.tab = tab;
    },
    show_comparison_modal() {
      this.comparison_modal = true;
    },
    close_comparison_modal() {
      this.comparison_modal = false;
    },
  },
  components: {
    "a2-comparision-modal": A2ComparisonModal,
  },
};
</script>
